import {
  ExamSession,
  ExamSessionByDate,
  ExamSessionProjection,
} from '@app/models/exam/examSession.model';
import { getDateDetails, toLocaleDateString } from './dateHelper';

export const sortExamSessionsByDate = (
  sessionsByDate: ExamSessionByDate,
): ExamSessionByDate => {
  const sortedEntries = Object.entries(sessionsByDate).sort(
    ([dateA], [dateB]) => {
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    },
  );

  return sortedEntries.reduce((acc, [date, sessions]) => {
    acc[date] = sessions;
    return acc;
  }, {} as ExamSessionByDate);
};

export const groupByLocaleDate = (
  examSessions: ExamSessionProjection[],
): ExamSessionByDate => {
  return examSessions.reduce<ExamSessionByDate>(
    (groupedExamSessions, examSession) => {
      if (examSession.sessionDate === undefined) {
        return groupedExamSessions;
      }

      // read UTC dates
      const date = new Date(examSession.sessionDate);

      // transform locale date to iso string format for grouping
      // no impact on timezone
      const dateString = toLocaleDateString(date);

      if (dateString === undefined) {
        return groupedExamSessions;
      }

      if (groupedExamSessions[dateString] === undefined) {
        groupedExamSessions[dateString] = [];
      }

      groupedExamSessions[dateString].push(examSession);
      return groupedExamSessions;
    },
    {},
  );
};

export const formatFrenchSessionDay = (
  sessionDate: string,
  monthLabels: string[],
): string => {
  // need to read these dates as UTC date => isUtc = true
  const { day, month, year } = getDateDetails(monthLabels, sessionDate, true);

  return `Sessions d'examen du ${day} ${month} ${year}`;
};

export const formatFrenchLocaleSessionDate = (
  monthLabels: string[],
  sessionDate?: string,
): string => {
  if (sessionDate === undefined) {
    return '';
  }

  const { day, month, year, hours, minutes } = getDateDetails(
    monthLabels,
    sessionDate,
  );
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${day} ${month} ${year} à ${formattedHours}h${formattedMinutes}`;
};

export const formatLocaleDateDDmonthYYYY = (
  monthLabels: string[],
  sessionDate?: string,
): string => {
  if (sessionDate === undefined) {
    return '';
  }

  const { day, month, year } = getDateDetails(monthLabels, sessionDate);

  return `${day} ${month} ${year}`;
};

export const formatLocaleSessionTime = (
  monthLabels: string[],
  sessionDate?: string,
): string => {
  if (sessionDate === undefined) {
    return '';
  }

  const { hours, minutes } = getDateDetails(monthLabels, sessionDate);
  if (minutes > 0) return `${hours} h ${minutes}`;

  return `${hours} h`;
};

export type ApiExamSession = Omit<ExamSession, 'id'> & {
  _links: {
    self: { href: string };
    [key: string]: { href: string };
  };
};

export const mapApiExamSession = (input: ApiExamSession): ExamSession => {
  const idRegex = /exam_session\/([a-f0-9-]+)$/;
  const selfHref = input._links?.self?.href;
  const match = RegExp(idRegex).exec(selfHref);
  const sessionId = match ? match[1] : '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _links, ...restSession } = input;

  return { ...restSession, id: sessionId };
};
