import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { UploadFile } from '@app/models/common/uploadFile.model';
import {
  ExamSession,
  ExamSessionByDate,
  ExamSessionProjection,
  ExamSessionStatusDTO,
} from '@app/models/exam/examSession.model';
import {
  ApiExamSession,
  groupByLocaleDate,
  mapApiExamSession,
  sortExamSessionsByDate,
} from '@app/utils/examSessions';
import { environement } from '@environments/environment';
import { UserExamSession } from '@models/exam/userExamSession.model';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';
import { EMPTY, from, map, Observable, switchMap, tap } from 'rxjs';
import { PageableResponse } from '@models/common/pageable-response.model';
import { PageRequest } from 'pf-ui';

export const defaultExamSessionStatusDTO: ExamSessionStatusDTO = {
  id: '',
  pin: '',
  hasClickedOnStartTraining: false,
  hasClickedOnStartExam: false,
  hasClickedOnShowResult: false,
  hasClickedOnSessionEnd: false,
  hasValidatedCandidatesInformation: false,
  allAreConnected: false,
  allHaveFinishedTraining: false,
  allHaveFinishedExam: false,
  userExamSessionStatusDTOS: [],
};

@Injectable({
  providedIn: 'root',
})
export class ExamSessionService extends BaseService {
  signalExamSession = signal<ExamSession | null>(null);
  signalExamSessions = signal<PageableResponse<ExamSessionProjection> | null>(
    null,
  );
  signalExamSessionsByDate = signal<ExamSessionByDate | null>(null);

  signalExamSessionStatus = signal<ExamSessionStatusDTO>(
    defaultExamSessionStatusDTO,
  );

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  getExamSession(examSessionId: string): Observable<ExamSession | null> {
    return this.executeRequest(
      this.http.get<ApiExamSession>(
        `${environement.BACKEND_URL}/exam_session/${examSessionId}`,
      ),
    ).pipe(
      map((apiExamSession) => mapApiExamSession(apiExamSession)),
      tap((examSession) => {
        this.signalExamSession.set(examSession);
      }),
    );
  }

  getExamSessionByPin(pin: string): Observable<ExamSession | null> {
    return this.executeRequest(
      this.http.get<ExamSession>(
        `${environement.BACKEND_URL}/exam_session/findByPinAndExpiredPinDateIsNull?pin=${pin}`,
      ),
    ).pipe(
      tap((examSession) => {
        this.signalExamSession.set(examSession);
      }),
    );
  }

  getAllExamSessionsUrl(
    params: HttpParams,
    date?: Date,
    islandId?: string,
  ): HttpParams {
    const dateParam = date ? date.toISOString() : undefined;
    if (dateParam !== undefined) {
      params = params.set('date', dateParam);
    }

    const islandIdParam =
      islandId !== null && islandId !== undefined
        ? encodeURIComponent(islandId)
        : undefined;
    if (islandIdParam !== undefined) {
      params = params.set('islandId', islandIdParam);
    }

    return params;
  }

  getAllExamSessions(
    date?: Date,
    islandId?: string,
    pageRequest?: PageRequest,
    endpoint?: string,
  ): Observable<PageableResponse<ExamSessionProjection> | null> {
    const params = this.getHttpParams({ ...pageRequest });
    return this.executeRequest(
      this.http.get<PageableResponse<ExamSessionProjection>>(
        endpoint ?? `${environement.BACKEND_URL}/exam_sessions`,
        { params: this.getAllExamSessionsUrl(params, date, islandId) },
      ),
    ).pipe(
      tap((examSessions) => {
        this.signalExamSessions.set(examSessions);
      }),
    );
  }

  getExamSessionsByDate(
    date?: Date,
    islandId?: string,
    pageRequest?: PageRequest,
  ): Observable<ExamSessionByDate | undefined> {
    return this.getAllExamSessions(
      date,
      islandId,
      pageRequest,
      `${environement.BACKEND_URL}/exam_sessions`,
    ).pipe(
      tap((examSessions) => this.signalExamSessions.set(examSessions)),
      map((examSessions) => {
        if (!examSessions) {
          return;
        }

        const examSessionByDate = sortExamSessionsByDate(
          groupByLocaleDate(examSessions.content),
        );
        this.signalExamSessionsByDate.set(examSessionByDate);

        return examSessionByDate;
      }),
    );
  }

  createExamSession(
    examSession: ExamSession,
    file?: UploadFile | null | undefined,
    submittingCallBack?: (isSubmitting: boolean) => void,
  ): Observable<ExamSession> {
    if (file == null) return EMPTY;
    return from(this.mapCreateExamSessionBody(file, examSession)).pipe(
      tap(() => submittingCallBack?.(true)),
      switchMap((data) => {
        const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
        const url = `${environement.BACKEND_URL}/session-provider/import-exam`;
        return this.executeRequest(
          this.http.post<ExamSession>(url, data, {
            headers,
          }),
        );
      }),
    );
  }

  async mapCreateExamSessionBody(
    uploadFile: UploadFile,
    examSession: ExamSession,
  ): Promise<FormData> {
    const mimeType = 'text/csv';
    const totalBase64 = `data:${mimeType};base64,${uploadFile.base64_file}`;
    const base64res = await fetch(totalBase64 || '');
    const blob = await base64res.blob();
    const file: File = new File([blob], uploadFile.name || '', {
      type: mimeType,
    });
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append(
      'examSession',
      new Blob([JSON.stringify(examSession)], { type: 'application/json' }),
    );
    return formData;
  }

  patch(examSession: ExamSession): void {
    this.executeRequest(
      this.http.patch<ExamSessionStatusDTO>(
        `${environement.BACKEND_URL}/exam_sessions/${examSession.id}`,
        { ...examSession },
      ),
    ).subscribe((updatedExamSession) => {
      this.signalExamSessionStatus.set(updatedExamSession);
    });
  }

  generatePinAndGetStatus(examSessionId: string): void {
    this.executeRequest(
      this.http.get<ExamSessionStatusDTO>(
        `${environement.BACKEND_URL}/exam_sessions/generate-pin/${examSessionId}`,
      ),
    ).subscribe((examSessionStatus) => {
      this.signalExamSessionStatus.set(examSessionStatus);
    });
  }

  getExamSessionStatus(
    examSessionId: string,
  ): Observable<ExamSessionStatusDTO> {
    return this.executeRequest(
      this.http.get<ExamSessionStatusDTO>(
        `${environement.BACKEND_URL}/exam_sessions/status/${examSessionId}`,
      ),
      { retryCount: 0 },
    ).pipe(
      tap((examSessionStatus) => {
        this.signalExamSessionStatus.set(examSessionStatus);
      }),
    );
  }

  updatePresence(examSessionId: string, providerId: string): void {
    this.executeRequest(
      this.http.get<ExamSessionStatusDTO>(
        `${environement.BACKEND_URL}/exam_sessions/${examSessionId}/update-user-presence/${providerId}`,
      ),
    ).subscribe((examSessionStatus) => {
      this.signalExamSessionStatus.set(examSessionStatus);
    });
  }

  getResultsCsv(examSessionId: string): Observable<Blob> {
    return this.executeRequest(
      this.http.get<Blob>(
        `${environement.BACKEND_URL}/exam_sessions/${examSessionId}/results-csv`,
        // Since the response is a binary file (CSV), it needs to be handled as a Blob rather than the default json.
        { responseType: 'blob' as 'json' },
      ),
    );
  }

  setValidatedCandidatesInformation(examSessionId: string): void {
    this.executeRequest(
      this.http.get<UserExamSession>(
        `${environement.BACKEND_URL}/exam_sessions/${examSessionId}/validated-candidates`,
      ),
    ).subscribe();
  }

  delete(sessionId: string): Observable<object> {
    return this.executeRequest(
      this.http.delete(
        `${environement.BACKEND_URL}/exam_sessions/${sessionId}`,
      ),
    );
  }
}
